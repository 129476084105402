import React, { useState, useEffect, useMemo } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
import DataGrid, { SelectColumn, TextEditor, SelectCellFormatter } from 'react-data-grid';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import HomerunEndDialog from "components/Dialog/HomerunEndDialog.js";
import ApplyDepositDialog from "components/Dialog/ApplyDepositDialog.js";
import ApplyEndDialog from "components/Dialog/ApplyEndDialog.js";
import ApplyItemDialog from "components/Dialog/ApplyItemDialog.js";
import * as config from '../../config.js'

const styles = {
  buttonDiv : {
    display : "inline-block",
    width : "100%"
  },
  addButton: {
    float : "right",
    marginRight : "15px"
  },
  dataGrid: {
    minHeight : "405px",
    textAlign : "center"
  },
  highlightRow : {
    backgroundColor : "#ebebeb",
    '&:hover': {
       backgroundColor : "#f2f2f2",
    },
  }
};

const useStyles = makeStyles(styles);
const week = ['일', '월', '화', '수', '목', '금', '토'];

const apply_columns = [
  { key: "index", name: "순번", minWidth:50, width: 50, resizable: false , frozen: true },
  { key: "type", name: "구분", minWidth:60, width: 60 },
  { key: "date", name: "날짜", width: 170, editor: TextEditor },
  { key: "field", name: "구장명", width: 100, editor: TextEditor },
  { key: "team_name", name: "팀이름", width: 120, editor: TextEditor },
  { key: "user_name", name: "유저이름", width: 80, editor: TextEditor },
  { key: "position", name: "포지션", minWidth:60, width: 60 , editor: TextEditor},
  { key: "inning_info", name: "이닝정보" , width: 70 , editor: TextEditor},
  { key: "batting_order", name: "타순", minWidth:50, width: 50 , editor: TextEditor},
  { key: "batter_number", name: "등번호", minWidth:60, width: 60 , editor: TextEditor},
  { key: "description", name: "정보", width: 450 , editor: TextEditor},
  { key: "public", name: "공개여부", width: 60 , editor: TextEditor},
  { key: "depositor_name", name: "입금자", width: 60 , editor: TextEditor},
  { key: "submit", name: "완료처리", width: 300 }
];

const homerun_columns = [
    { key: "index", name: "순번", minWidth:60, width: 60, resizable: false , frozen: true },
    { key: "date", name: "날짜", width: 200, editor: TextEditor },
    { key: "time", name: "시간", width: 100 , editor: TextEditor},
    { key: "field", name: "구장명", width: 120, editor: TextEditor },
    { key: "team_name", name: "팀이름", width: 120, editor: TextEditor },
    { key: "user_name", name: "유저이름", width: 120, editor: TextEditor },
    { key: "position", name: "포지션" , minWidth:60, width: 60 , editor: TextEditor},
    { key: "description", name: "정보" , width: 700, editor: TextEditor},
    { key: "submit", name: "완료처리" , width: 200}
];

export default function ApplyList() {
  const classes = useStyles();
  const [applyRows, setApplyRows] = useState([])
  const [homerunRows,setHomerunRows] = useState([])
  const [sortApplyColumns, setSortApplyColumns] = useState([]);
  const [sortHomerunColumns, setSortHomerunColumns] = useState([]);
  const [homerunList, setHomerunList] = useState([]);
  const [applyList, setApplyList] = useState([]);
  const [homerunEndIndex,setHomerunEndIndex] = useState(0);
  const [applyDepositIndex,setApplyDepositIndex] = useState(0);
  const [applyEndIndex,setApplyEndIndex] = useState(0);
  const [applyItemDialogOpen,setApplyItemDialogOpen] = useState(false);
  const [homerunEndDialogOpen, setHomerunEndDialogOpen] = useState(false);
  const [applyDepositDialogOpen, setApplyDepositDialogOpen] = useState(false);
  const [applyEndDialogOpen, setApplyEndDialogOpen] = useState(false);
  const [homerunIndexDict, setHomerunIndexDict] = useState(false);
  const [applyIndexDict, setapplyIndexDict] = useState(false);

  const applyTypeToString = (apply_type, apply_sub_type) =>{
    var apply_string = ""
    if(apply_type == "full"){
      apply_string = "풀영상"
    }
    else{
      if(apply_sub_type == "single"){
        apply_string = "한타석"
      }
      else if(apply_sub_type == "all"){
        apply_string = "전타석"
      }
      else if(apply_sub_type == "pitcher"){
        apply_string = "투수"
      }
    }

    return apply_string;
  }

  const applyPulbicToString = (apply_public) => {
    var public_string = ""

    if(apply_public == "admit"){
      public_string = "공개"
    }
    else{
      public_string = "비공개"
    }
    return public_string;
  }

  const stringToApplyPublic = (public_string) => {
    var apply_public = ""

    if(public_string == "공개"){
      apply_public = "admit"
    }
    else{
      apply_public = "disadmit"
    }
    return apply_public;
  }

  const timestampToDateString = (timestamp) =>{
    const datetime = new Date(timestamp)
    const dateString = dateFormat(datetime, "yy년mm월dd일("+ week[datetime.getDay()]+") HH:MM");
    return dateString;
  }
  const dateStringToTimestamp = (datestring) =>{
    var datestring_split = datestring.split(' ')
    var timestamp = "20" + datestring_split[0].substr(0,2) + "-" + datestring_split[0].substr(3,2) + "-" + datestring_split[0].substr(6,2) + " "
    timestamp += datestring_split[1] + ":00"

    return timestamp
  }

  const handleItemDialogOpen = () => {
      setApplyItemDialogOpen(true);
    };

  const handleItemDialogClose = () => {
    setApplyItemDialogOpen(false);
  };

  const handleApplyDepositDialogOpen = () =>{
    setApplyDepositDialogOpen(true);
  }
  const handleApplyDepositDialogClose = () =>{
    setApplyDepositDialogOpen(false);
  }
  const handleApplyDeposit = (event) =>{
    setApplyDepositIndex(event.currentTarget.value);
    handleApplyDepositDialogOpen();
  }

  const handleApplyEndDialogOpen = () =>{
    setApplyEndDialogOpen(true);
  }
  const handleApplyEndDialogClose = () =>{
    setApplyEndDialogOpen(false);
  }
  const handleApplyEnd = (event) =>{
    setApplyEndIndex(event.currentTarget.value);
    handleApplyEndDialogOpen();
  }

  const handleHomerunEndDialogOpen = () =>{
    setHomerunEndDialogOpen(true);
  }
  const handleHomerunEndDialogClose = () =>{
    setHomerunEndDialogOpen(false);
  }
  const handleHomerunEnd = (event) => {
    setHomerunEndIndex(event.currentTarget.value);
    handleHomerunEndDialogOpen();
  }

  const tokenRefresh = () => {
    const refresh_token = localStorage.getItem('refresh_token');
    const url = config.API_SERVER_URI + "/reissueToken";

    axios.post(url, {},{
      headers : {
        'Authorization' : 'Bearer ' + refresh_token
      }
    })
    .then(function (response) {
      if(response.data.response == true){
        //토큰 업데이트
        localStorage.setItem('access_token',response.data.accesss_token);
        if(response.data.refresh_token){
          localStorage.setItem('refresh_token',response.data.refresh_token);
        }
      }
      else{
        alert("재로그인이 필요합니다.");
        window.location.href="/login"
      }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
    });
  }

  const LoadHomeruns = () =>{
    const access_token = localStorage.getItem('access_token');

    var url = config.API_SERVER_URI + "/autho/select/homerun"
    var thisHomerunIndexDict = {}
    var thisHomerunList = []
    var thisHomerunRows = []
    axios.post(url, {
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response.data);
      var count = 1
      for(const index in response.data){
        const this_data = response.data[index]
        thisHomerunIndexDict[this_data['homerun_index']] = this_data
        thisHomerunList.push([count,timestampToDateString(this_data['homerun_play_date']),
          this_data['homerun_hit_time'],this_data['homerun_field'],
          this_data['homerun_team_name'],this_data['homerun_user_name'],
          this_data['homerun_user_position'],this_data['homerun_description'],
          <div>
            {
              (this_data['homerun_end_date'] == null || this_data['homerun_end_date'] == '')?
              <Button color="success" value={this_data['homerun_index']} onClick={handleHomerunEnd}>완료</Button>
              :
              <Button color="success" disabled>완료</Button>
            }
          </div>
        ])

        var this_row = {
          index :count,
          date: timestampToDateString(this_data['homerun_play_date']),
          time:this_data['homerun_hit_time'],
          field: this_data['homerun_field'],
          team_name: this_data['homerun_team_name'],
          user_name: this_data['homerun_user_name'],
          position : this_data['homerun_user_position'],
          description : this_data['homerun_description'],
          submit : <div>
            {
              (this_data['homerun_end_date'] == null || this_data['homerun_end_date'] == '')?
              <Button color="success" value={this_data['homerun_index']} onClick={handleHomerunEnd}>완료</Button>
              :
              <Button color="success" disabled>완료</Button>
            }
          </div>,
          homerun_index : this_data['homerun_index']
        }

        thisHomerunRows.push(this_row)

        count += 1
      }
    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
        setHomerunIndexDict(thisHomerunIndexDict);
        setHomerunList(thisHomerunList);
        setHomerunRows(thisHomerunRows);
    });
  }

  const LoadApplys = () =>{

    const access_token = localStorage.getItem('access_token');
    var url = config.API_SERVER_URI + "/autho/select/apply"
    var thisApplyList = []
    var thisApplyRows = []
    var thisApplyIndexDict = {}
    axios.post(url, {
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response.data);
      var count = 1
      for(const index in response.data){
        const this_data = response.data[index];
        console.log(index);
        console.log(this_data);
        console.log(this_data['apply_play_date']);
        thisApplyIndexDict[this_data['apply_index']] = this_data
        thisApplyList.push([count,applyTypeToString(this_data['apply_type'],this_data['apply_sub_type']),
          timestampToDateString(this_data['apply_play_date']),
          this_data['apply_field'],this_data['apply_team_name'],
          this_data['apply_user_name'],this_data['apply_user_position'],
          this_data['apply_inning_info'],this_data['apply_batting_order'],
          this_data['apply_user_number'],this_data['apply_description'],
          applyPulbicToString(this_data['apply_is_public']),this_data['apply_depositor_name'],
          <div>
            {
              (this_data['apply_deposit_check'] == null || this_data['apply_deposit_check'] == 0)?
              <Button color="success" value={this_data['apply_index']} onClick={handleApplyDeposit}>입금완료</Button>
              :
              <Button color="success" disabled>입금완료</Button>
            }
            {
              (this_data['apply_send_check'] == null || this_data['apply_send_check'] == 0)?
              <Button color="success" value={this_data['apply_index']} onClick={handleApplyEnd}>링크전송</Button>
              :
              <Button color="success" disabled>링크전송</Button>
            }
          </div>
        ])


        var this_row = {
          index :count,
          type: applyTypeToString(this_data['apply_type'],this_data['apply_sub_type']),
          date: timestampToDateString(this_data['apply_play_date']),
          field: this_data['apply_field'],
          team_name: this_data['apply_team_name'],
          user_name: this_data['apply_user_name'],
          position: this_data['apply_user_position'],
          inning_info: this_data['apply_inning_info'],
          batting_order: this_data['apply_batting_order'],
          batter_number: this_data['apply_user_number'],
          description : this_data['apply_description'],
          public : applyPulbicToString(this_data['apply_is_public']),
          depositor_name : this_data['apply_depositor_name'],
          submit:
          <div>
            {
              (this_data['apply_deposit_check'] == null || this_data['apply_deposit_check'] == 0)?
              <Button color="success" value={this_data['apply_index']} onClick={handleApplyDeposit}>입금완료</Button>
              :
              <Button color="success" disabled>입금완료</Button>
            }
            {
              (this_data['apply_send_check'] == null || this_data['apply_send_check'] == 0)?
              <Button color="success" value={this_data['apply_index']} onClick={handleApplyEnd}>링크전송</Button>
              :
              <Button color="success" disabled>링크전송</Button>
            }
          </div>,
          apply_index : this_data['apply_index'],
          is_end : (this_data['apply_send_check'] == null || this_data['apply_send_check'] == 0) ? false : true
        }

        thisApplyRows.push(this_row)


        count+=1
      }
    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
        setapplyIndexDict(thisApplyIndexDict);
        setApplyList(thisApplyList);
        setApplyRows(thisApplyRows);
    });
  }

  useEffect(() => {

    const access_token = localStorage.getItem('access_token');
    if(access_token == null || access_token == undefined){
      alert("로그인이 필요합니다.")
      window.location.href="/login"
    }
    else{
      tokenRefresh();
    }

    LoadHomeruns();
    LoadApplys();

  }, [])

  const rowKeyGetter = (row) =>{
    return row.id;
  }

  const onApplyRowsChange = (changed_rows) =>{
    //console.log(changed_rows);
    for(var i in changed_rows){
      if(applyRows[i] != changed_rows[i]){
        console.log(applyRows[i]);
        console.log(changed_rows[i]);

        const access_token = localStorage.getItem('access_token');
        const url = config.API_SERVER_URI + "/autho/update/apply/withindex"
        axios.post(url, {
          apply_index : changed_rows[i].apply_index,
          apply_play_date : dateStringToTimestamp(changed_rows[i].date),
          apply_field : changed_rows[i].field,
          apply_team_name : changed_rows[i].team_name,
          apply_user_name : changed_rows[i].user_name,
          apply_inning_info : changed_rows[i].inning_info,
          apply_batting_order : changed_rows[i].batting_order,
          apply_user_number : changed_rows[i].batter_number,
          apply_user_position : changed_rows[i].position,
          apply_description : changed_rows[i].description,
          apply_depositor_name : changed_rows[i].depositor_name,
          apply_is_public : stringToApplyPublic(changed_rows[i].public)
        },{
          headers : {
            'Authorization' : 'Bearer ' + access_token
          }
        })
        .then(function (response) {
          console.log(response.data);
        }).catch(function (error) {
            // 오류발생시 실행
            console.log(error);
        }).then(function() {
            // 항상 실행
        });
      }
    }
    setApplyRows(changed_rows)
  }

  const onHomerunRowsChange = (changed_rows) => {
    //console.log(changed_rows);
    for(var i in changed_rows){
      if(homerunRows[i] != changed_rows[i]){
        console.log(homerunRows[i]);
        console.log(changed_rows[i]);

        const access_token = localStorage.getItem('access_token');
        const url = config.API_SERVER_URI + "/autho/update/homerun/withindex"
        axios.post(url, {
          homerun_index : changed_rows[i].homerun_index,
          homerun_play_date : dateStringToTimestamp(changed_rows[i].date),
          homerun_hit_time : changed_rows[i].time,
          homerun_field : changed_rows[i].field,
          homerun_team_name : changed_rows[i].team_name,
          homerun_user_name : changed_rows[i].user_name,
        },{
          headers : {
            'Authorization' : 'Bearer ' + access_token
          }
        })
        .then(function (response) {
          console.log(response.data);
        }).catch(function (error) {
            // 오류발생시 실행
            console.log(error);
        }).then(function() {
            // 항상 실행
        });
      }
    }
    setHomerunRows(changed_rows)
  }

  function getComparator(sortColumn): Comparator {
    switch (sortColumn) {
      case 'type':
      case 'date':
      case 'league_type':
      case 'field':
      case 'team_name':
      case 'user_name':
      case 'position':
      case 'inning_info':
      case 'public':
      case 'depositor_name':
      case 'description':
      case 'time':
        return (a, b) => {
          return a[sortColumn].localeCompare(b[sortColumn]);
        };
      case 'index':
      case 'batting_order':
      case 'batter_number':
        return (a, b) => {
          return a[sortColumn] - b[sortColumn];
        };
      default:
        return (a, b) => {
          return 0;
        };
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }

  const sortedApplyRows = useMemo(() => {
    if (sortApplyColumns.length === 0) return applyRows;

    const sortedRows = [...applyRows];
    sortedRows.sort((a, b) => {
      for (const sort of sortApplyColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sortedRows;
  }, [applyRows, sortApplyColumns]);

  const sortedHomerunRows = useMemo(() => {
    if (sortHomerunColumns.length === 0) return homerunRows;

    const sortedRows = [...homerunRows];
    sortedRows.sort((a, b) => {
      for (const sort of sortHomerunColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sortedRows;
  }, [homerunRows, sortHomerunColumns]);

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="rose">
            <LibraryBooks />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <div className={classes.buttonDiv}>
            <Button className={classes.addButton} type="button" color="info" onClick={handleItemDialogOpen}>추가</Button>
          </div>
          <div>
            <Tabs
              title="신청타입 : "
              headerColor="rose"
              tabs={[
                {
                  tabName:"개인영상",
                  tabContent: (
                    <DataGrid
                      columns={apply_columns}
                      rowGetter={rowKeyGetter}
                      rowsCount={50}
                      rows={sortedApplyRows}
                      defaultColumnOptions={{
                            sortable: true,
                            resizable: true
                          }}
                      onRowsChange={onApplyRowsChange}
                      sortColumns={sortApplyColumns}
                      onSortColumnsChange={setSortApplyColumns}
                      rowHeight={55}
                      className={classes.dataGrid}
                      rowClass={(row) => (row.is_end == true ? classes.highlightRow : undefined)}
                    />
                    /*
                    <Table
                      tableHeaderColor="primary"
                      tableHead={["순번","구분", "날짜", "구장명", "팀이름", "유저이름", "포지션","이닝정보","타순","등번호","정보","공개여부","입금자","완료처리"]}
                      tableData={applyList}
                    />
                    */
                  )
                },
                {
                  tabName:"홈런",
                  tabContent: (
                    <DataGrid
                      columns={homerun_columns}
                      rowGetter={rowKeyGetter}
                      rowsCount={50}
                      rows={sortedHomerunRows}
                      defaultColumnOptions={{
                            sortable: true,
                            resizable: true
                          }}
                      onRowsChange={onHomerunRowsChange}
                      sortColumns={sortHomerunColumns}
                      onSortColumnsChange={setSortHomerunColumns}
                      rowHeight={50}
                      className={classes.dataGrid}
                    />
                    /*
                    <Table
                      tableHeaderColor="primary"
                      tableHead={["순번","날짜", "해당 시간", "구장명", "팀이름", "유저이름", "포지션","정보","완료처리"]}
                      tableData={homerunList}
                    />
                    */

                  )
                }
              ]}
            />
          </div>
        </CardBody>
      </Card>

      <ApplyItemDialog
        applyItemDialogOpen={applyItemDialogOpen}
        handleItemDialogClose={handleItemDialogClose}
        LoadApplys={LoadApplys}
        LoadHomeruns={LoadHomeruns}
      />

      <HomerunEndDialog
        homerunEndDialogOpen={homerunEndDialogOpen}
        homerunEndIndex={homerunEndIndex}
        homerunIndexDict = {homerunIndexDict}
        handleHomerunEndDialogClose={handleHomerunEndDialogClose}
        LoadHomeruns={LoadHomeruns}
      />

      <ApplyDepositDialog
        applyDepositDialogOpen={applyDepositDialogOpen}
        applyDepositIndex={applyDepositIndex}
        applyIndexDict={applyIndexDict}
        handleApplyDepositDialogClose={handleApplyDepositDialogClose}
        LoadApplys={LoadApplys}
      />
      <ApplyEndDialog
        applyEndDialogOpen={applyEndDialogOpen}
        applyEndIndex={applyEndIndex}
        applyIndexDict={applyIndexDict}
        handleApplyEndDialogClose={handleApplyEndDialogClose}
        LoadApplys={LoadApplys}
      />

    </div>
  );
}
