import React, { useState, useEffect } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import * as config from '../../config.js'

const useStyles = makeStyles({
  table: {
    minWidth: 800,
  },
});

export default function AccountingInsertDialog(props) {

  const classes = useStyles();

  const [accountingList, setAccountingList] = useState('');

  const handleListChange = (event) => {
    setAccountingList(event.target.value);
  }

  const insertRequestSend = () =>{
    console.log("확인");

    const access_token = localStorage.getItem('access_token');
    const url = config.API_SERVER_URI + "/autho/insert/accounting/list"
    axios.post(url, {
      accounting_list : props.insertAccountingList,
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    }).then(function() {
      props.handleInsertDialogClose();
      props.LoadAccountings();
    });
  }

  const makeNumberDot = (target_number) =>{
    return target_number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  }

  const tableBody = props.insertAccountingList.map((row,i) => (
    <TableRow key={row.name}>
      <TableCell component="th" scope="row">
        {i+1}
      </TableCell>
      <TableCell align="right">{row[0]}</TableCell>
      <TableCell align="right">{row[1]}</TableCell>
      <TableCell align="right">{makeNumberDot(row[2])}</TableCell>
      <TableCell align="right">{makeNumberDot(row[3])}</TableCell>
      <TableCell align="right">{makeNumberDot(row[4])}</TableCell>
    </TableRow>
  ))

  return(
    <Dialog className={classes.root} maxWidth={'xl'} open={props.accountingInsertDialogOpen} onClose={props.handleInsertDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">거래 내역 추가</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>순번</TableCell>
                <TableCell>날짜</TableCell>
                <TableCell align="right">항목</TableCell>
                <TableCell align="right">출금</TableCell>
                <TableCell align="right">입금</TableCell>
                <TableCell align="right">잔고</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableBody}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleInsertDialogClose} color="error">
          취소
        </Button>
        <Button onClick={insertRequestSend} color="info">
          추가
        </Button>
      </DialogActions>
    </Dialog>

  );
}
