import React, { useState, useEffect } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CreditCard from "@material-ui/icons/CreditCard";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import AccountingItemDialog from "components/Dialog/AccountingItemDialog.js";
import * as config from '../../config.js'

import uniqueplay_marker from "markers/uniqueplay-marker.png";
import gameone_marker from "markers/gameone-marker.png";
import spobee_marker from "markers/spobee-marker.png";
import motionbaseball_marker from "markers/motionbaseball-marker.png";
import catchform_marker from "markers/catchform-marker.png";
import secondground_marker from "markers/secondground-marker.png";

const { kakao } = window;

const styles = {
  addButton: {
    float : "right",
    marginRight : "15px"
  },
  map_wrap: {
    position : "relative",
    overflow : "hidden",
    width : "100%",
    height : "800px",
    marginTop : "30px"
  },
  map:{
    width:"100%",
    height:"100%",
    position:"relative",
    overflow:"hidden"
  },
  custom_typecontrol: {
    position:"absolute",
    top:"10px",
    right:"10px",
    overflow:"hidden",
    width:"130px",
    height:"30px",
    margin:0,
    padding:0,
    zIndex:1,
    fontSize:"12px",
    fontFamily:"'Malgun Gothic', '맑은 고딕', sans-serif",
    border:"1px solid #919191",
    borderRadius:"5px",
  },
  btn:{
    display:"block",
    width:"65px",
    height:"30px",
    float:"left",
    textAlign:"center",
    lineHeight:"30px",
    cursor:"pointer",

    background:"#fff",
    background:"linear-gradient(#fff,  #e6e6e6)",
    '&:hover':{
      background:"#f5f5f5",
      background:"linear-gradient(#f5f5f5,#e3e3e3)"
    },
    '&:active':{
      background:"#e6e6e6",
      background:"linear-gradient(#e6e6e6, #fff)"
    }
  },
  selected_btn: {
    display:"block",
    width:"65px",
    height:"30px",
    float:"left",
    textAlign:"center",
    lineHeight:"30px",
    cursor:"pointer",

    color:"#fff",
    background:"#425470",
    background:"linear-gradient(#425470, #5b6d8a)",
    '&:hover':{
      color:"#fff"
    }
  },

  custom_zoomcontrol: {
    position:"absolute",
    top:"50px",
    right:"10px",
    width:"36px",
    height:"80px",
    overflow:"hidden",
    zIndex:1,
    backgroundColor:"#f5f5f5",
    border:"1px solid #919191",
    borderRadius:"5px",
  },
  zoom_span:{
    display:"block",
    width:"36px",
    height:"40px",
    textAlign:"center",
    cursor:"pointer",
    "&:nth-child(1)": {
       borderBottom:"1px solid #bfbfbf"
    }
  },
  zoom_img:{
    width:"15px",
    height:"15px",
    padding:"12px 0",
    border:"none"
  },
  infoTitle: {
    display: "block",
    background: "#50627F",
    color: "#fff",
    textAlign: "center",
    height: "24px",
    lineHeight:"22px",
    borderRadius:"4px",
    padding:"0px 10px"
  }

};


const useStyles = makeStyles(styles);
export default function Map() {

  const classes = useStyles();
  const [kakaoMap, setKakaoMap] = useState(0);

  const tokenRefresh = () => {
    const refresh_token = localStorage.getItem('refresh_token');
    const url = config.API_SERVER_URI + "/reissueToken";

    axios.post(url, {},{
      headers : {
        'Authorization' : 'Bearer ' + refresh_token
      }
    })
    .then(function (response) {
      if(response.data.response == true){
        //토큰 업데이트
        localStorage.setItem('access_token',response.data.accesss_token);
        if(response.data.refresh_token){
          localStorage.setItem('refresh_token',response.data.refresh_token);
        }
      }
      else{
        alert("재로그인이 필요합니다.");
        window.location.href="/login"
      }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
    });
  }

  useEffect(() => {

    const access_token = localStorage.getItem('access_token');
    if(access_token == null || access_token == undefined){
      alert("로그인이 필요합니다.")
      window.location.href="/login"
    }
    else{
      tokenRefresh();
    }

    const container = document.getElementById('myMap');
  	const options = {
  		center: new kakao.maps.LatLng(37.563691269995196, 127.0357019114867),
  		level: 3
  	};
    const map = new kakao.maps.Map(container, options);
    setKakaoMap(map)

    LoadLocations(map);

  }, []);

  const LoadLocations = (map) =>{
    const access_token = localStorage.getItem('access_token');

    var url = config.API_SERVER_URI + "/autho/select/location"

    axios.post(url, {
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      makeMakerInfowindow(map, response.data)
    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
    });
  }

  const makeMakerInfowindow = (map,locationList) =>{
    for (var i in locationList){
      var this_location = locationList[i]

      var marker = ""
      var imageSrc = ""

      if(this_location['location_service'] == "유니크플레이"){
        imageSrc = uniqueplay_marker;
      }
      else if(this_location['location_service'] == "게임원 플레이"){
        imageSrc = gameone_marker;
      }
      else if(this_location['location_service'] == "스포비"){
        imageSrc = spobee_marker;
      }
      else if(this_location['location_service'] == "캐치폼"){
        imageSrc = catchform_marker;
      }
      else if(this_location['location_service'] == "모션베이스볼"){
        imageSrc = motionbaseball_marker;
      }
      else if(this_location['location_service'] == "세컨그라운드"){
        imageSrc = secondground_marker;
      }

      var imageSize = new kakao.maps.Size(46,46);
      var imageOption = {offset: new kakao.maps.Point(17,34)}
      var markerImage = new kakao.maps.MarkerImage(imageSrc,imageSize,imageOption)

      //마커 만들기
      if(imageSrc == ""){
        marker = new kakao.maps.Marker({
          map: map,
          position: new kakao.maps.LatLng(this_location['location_y'], this_location['location_x'])
        });
      }
      else{
        marker = new kakao.maps.Marker({
          map: map,
          position: new kakao.maps.LatLng(this_location['location_y'], this_location['location_x']),
          image : markerImage
        });
      }

      //infowwindow 연결
      var infowindow = new kakao.maps.InfoWindow({
        content: '<div style="width:200px;text-align:center;padding-top:2px;padding-bottom:2px;">'+this_location['location_league_name'] + "(" + this_location['location_scale'] + ")"+'</div>'+
                '<div style="width:200px;text-align:center;padding-top:2px;padding-bottom:2px;">'+this_location['location_field_name']+'</div>',
        disableAutoPan: true
       });

      kakao.maps.event.addListener(marker,"mouseover",  makeOverListener(map, marker, infowindow));
      kakao.maps.event.addListener(marker,"mouseout",makeOutListener(infowindow));
    }
  }

  // 인포윈도우를 표시하는 클로저를 만드는 함수입니다
  function makeOverListener(map, marker, infowindow) {
    return function () {
      infowindow.open(map, marker);
    };
  }

  // 인포윈도우를 닫는 클로저를 만드는 함수입니다
  function makeOutListener(infowindow) {
    return function () {
      infowindow.close();
    };
  }

  const setMapType = (maptype) => {
    var roadmapControl = document.getElementById('btnRoadmap');
    var skyviewControl = document.getElementById('btnSkyview');
    if (maptype === 'roadmap') {
        kakaoMap.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
        roadmapControl.className = classes.selected_btn;
        skyviewControl.className = classes.btn;
    } else {
        kakaoMap.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
        skyviewControl.className = classes.selected_btn;
        roadmapControl.className = classes.btn;
    }
  }

  const zoomIn = () => {
    kakaoMap.setLevel(kakaoMap.getLevel() - 1);
  }

  const zoomOut = () => {
      kakaoMap.setLevel(kakaoMap.getLevel() + 1);
  }

  return (
    <div>
      <div className={classes.map_wrap}>
        <div id='myMap' className={classes.map} />
        <div className={classes.custom_typecontrol}>
            <span id="btnRoadmap" className={classes.selected_btn} onClick={() => setMapType('roadmap')}>지도</span>
            <span id="btnSkyview" className={classes.btn} onClick={() => setMapType('skyview')} >스카이뷰</span>
        </div>
        <div className={classes.custom_zoomcontrol}>
            <span className={classes.zoom_span} onClick={zoomIn}>
              <img className={classes.zoom_img} src="http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png" alt="확대" />
            </span>
            <span className={classes.zoom_span} onClick={zoomOut}>
              <img className={classes.zoom_img} src="http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png" alt="축소" />
            </span>
        </div>
      </div>
    </div>
  );
}
