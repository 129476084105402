import React, { useState, useEffect } from 'react';
import axios from "axios";
import 'date-fns';
import dateFormat from "dateformat";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Person from '@material-ui/icons/Person';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import UserItemDialog from "components/Dialog/UserItemDialog.js";
import * as config from '../../config.js'

const styles = {
  filterDiv : {
    display : "inline-block"
  },
  filterItems : {
    float:"left"
  },
  addButton: {
    float : "right",
    marginRight : "15px"
  }
};

const useStyles = makeStyles(styles);

export default function User() {
  const classes = useStyles();
  const [searchFilter, setSearchFilter] = useState('이름');
  const [userList, setUserList] = useState([]);
  const [dialogOpen,setDialogOpen] = useState(false);

  const handleDialogOpen = () =>{
    setDialogOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSearchFilterChange = (event) =>{
    setSearchFilter(event.target.value)
  }

  const tokenRefresh = () => {
    const refresh_token = localStorage.getItem('refresh_token');
    const url = config.API_SERVER_URI + "/reissueToken";

    axios.post(url, {},{
      headers : {
        'Authorization' : 'Bearer ' + refresh_token
      }
    })
    .then(function (response) {
      if(response.data.response == true){
        //토큰 업데이트
        localStorage.setItem('access_token',response.data.accesss_token);
        if(response.data.refresh_token){
          localStorage.setItem('refresh_token',response.data.refresh_token);
        }
      }
      else{
        alert("재로그인이 필요합니다.");
        window.location.href="/login"
      }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
    });
  }
  const LoadUsers = () =>{
    const access_token = localStorage.getItem('access_token');
    const url = config.API_SERVER_URI + "/autho/select/member"
    const thisUserList = []
    axios.post(url, {
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
         console.log(response.data);
         for(const index in response.data){
           const this_data = response.data[index];
             thisUserList.push([this_data['member_name'],this_data['member_phone_number']
             ,this_data['member_team_name'],this_data['member_league_name'],
             <div>
               {
                 (this_data['member_kakao_url'] == null || this_data['member_kakao_url'] == "") ?
                 "" :
                 <a href={this_data['member_kakao_url']} target='_blank'>플러스친구</a>
               }
             </div>

           ])
         }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
        setUserList(thisUserList);
    });
  }

  useEffect(() => {

    const access_token = localStorage.getItem('access_token');
    if(access_token == null || access_token == undefined){
      alert("로그인이 필요합니다.")
      window.location.href="/login"
    }
    else{
      tokenRefresh();
    }

    LoadUsers();

  }, [])

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="rose">
            <Person />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {
            /* 필터
            <div>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchFilter}
                onChange={handleSearchFilterChange}
              >
                <MenuItem value={"이름"}>이름</MenuItem>
                <MenuItem value={"휴대폰번호"}>휴대폰번호</MenuItem>
                <MenuItem value={"팀명"}>팀명</MenuItem>
              </Select>
              <CustomInput
                id="regular"
                inputProps={{
                  placeholder: "Regular"
                }}
                formControlProps={{
                }}
              />
              <Button type="button" color="primary">검색</Button>
            </div>
            */
          }
          <Button className={classes.addButton} type="button" color="info" onClick={handleDialogOpen}>추가</Button>
          <Table
            tableHeaderColor="primary"
            tableHead={["이름", "휴대폰번호", "팀명", "리그명", "플친"]}
            tableData={userList}
          />
        </CardBody>
      </Card>
      <UserItemDialog
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        LoadUsers={LoadUsers}
      />

    </div>
  );
}
