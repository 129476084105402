import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
import { CSVLink, CSVDownload } from "react-csv";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import * as config from '../../config.js'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '500px',
    },
  },
}));

const getYearStr = (y) => {
  return String(y) + "년";
}
const monthList = ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월']

const headers = [
  { label: "순번", key: "index" },
  { label: "구분", key: "type" },
  { label: "날짜", key: "date" },
  { label: "리그분류", key: "league_type" },
  { label: "야구장", key: "field" },
  { label: "게임", key: "game" }
];

export default function PlayExcelDialog(props) {

  const classes = useStyles();
  const csvLink = useRef()

  const [targetYear, setTargetYear] = useState(22);    // 뽑을 엑셀의 연도
  const [targetMonth, setTargetMonth] = useState(0);  // 뽑을 엑셀의 월
  const [targetField, setTagetField] = useState(0);   // 뽑을 엑셀의 경기장
  const [excelData, setExcelData] = useState(false);

  const handelTargetYear = (event) => {
    setTargetYear(event.target.value);
  }

  const handelTargetMonth = (event) =>{
    setTargetMonth(event.target.value);
  }

  const handleTargetField = (event) => {
    setTagetField(event.target.value);
  }

  const bringYearFromDate = (this_date) => {
    const yearInt = parseInt(this_date.substr(0,2))
    return String(yearInt) + "년"
  }

  const bringMonthFromDate = (this_date) => {
    const monthInt = parseInt(this_date.substr(3,2))
    return String(monthInt) + "월"
  }


  useEffect(() => {
    if (excelData && csvLink.current && csvLink.current.link) {
      setTimeout(() => {
        csvLink.current.link.click();
        setExcelData(false);
      });
    }
  }, [excelData]);

  const bringData = () => {
    const totalPlayList = props.playList;
    var dataList = [];
    var count = 1;

    for (var this_play of totalPlayList){
      var this_data ={}

      //해당 년, 월과 구장 가져오기
      if(bringYearFromDate(this_play[2]) != getYearStr(targetYear) || bringMonthFromDate(this_play[2]) != monthList[targetMonth] || this_play[4] != props.fieldList[targetField] &&
        props.fieldList[targetField] != "전체"){
          continue;
      }

      if(this_play[1] == '리그'){
        this_data['index'] = count
        this_data['type'] = this_play[1]
        this_data['date'] = this_play[2]
        this_data['league_type'] = this_play[3]
        this_data['field'] = this_play[4]
        this_data['game'] = this_play[5] + " vs " + this_play[6]
        count += 1
       }
      else{
        this_data['index'] = count
        this_data['type'] = this_play[1]
        this_data['date'] = this_play[2]
        this_data['field'] = this_play[4]
        count += 1
      }
      dataList.push(this_data)
    }

    console.log(dataList);
    setExcelData(dataList);
  }

  const excelExport = () =>{
    console.log("요청");
    bringData();
    props.handleExcelDialogClose();
  }

  const yearList = ["20년", "21년", "22년", "23년", "24년", "25년"]
  const yearMenuItems = yearList.map((year, i) =>{
    return (
      <MenuItem value={20 + i}>{year}</MenuItem>
    )
  })

  const monthMenuItems = monthList.map((month, i) =>{
    return (
      <MenuItem value={i}>{month}</MenuItem>
    )
  })

  const fieldMenuItems = props.fieldList.map((field,i) => {
    return (
      <MenuItem value={i}>{field}</MenuItem>
    )
  })

  return(
    <Dialog className={classes.root} open={props.playExcelDialogOpen} onClose={props.handleExcelDialogClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">엑셀 뽑기</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12}>
            <Select
              value={targetYear}
              onChange={handelTargetYear}
              fullWidth
            >
              {yearMenuItems}
            </Select>
          </GridItem>

          <GridItem xs={12}>
            <Select
              value={targetMonth}
              onChange={handelTargetMonth}
              fullWidth
            >
              {monthMenuItems}
            </Select>
          </GridItem>

          <GridItem xs={12}>
            <Select
              value={targetField}
              onChange={handleTargetField}
              fullWidth
            >
              {fieldMenuItems}
            </Select>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleExcelDialogClose} color="error">
          취소
        </Button>
        <Button onClick={excelExport} color="info">
          완료
        </Button>
      </DialogActions>

      {excelData &&
        <CSVLink
          data={excelData} headers={headers}
          filename={"20" + getYearStr(targetYear) + " "+monthList[targetMonth] + " " + props.fieldList[targetField] +".csv"}
          ref={csvLink}
        />
      }

    </Dialog>

  );
}
