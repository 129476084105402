import React, { useState, useEffect } from 'react';
import axios from "axios";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";
import asset1 from "sprites/Asset1.png"

import './Navbar.css';
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import * as config from '../../config.js'

const useStyles = makeStyles(styles);

const aniStyles = makeStyles({
  aniDiv : {
    height : "70px"
    //display : "inline-block"
  },
  sungil : {
    width : "50px",
    height : "100%",
    background: 'url(' + asset1 + ')',
    backgroundSize: "auto 100%",
    animation: "myEffect 1s steps(5) infinite"
  },
  "@keyframes myEffect": {
    "100%": {
      backgroundPosition: "-2393px 0"
    }
  },
});

export default function Header(props) {
  const classes = useStyles();
  const ani_classes = aniStyles();

  const [yooDays, setYooDays] = useState(0);
  const [sungilDays, setSungilDays] = useState(0);
  const [teniDays, setTeniDays] = useState(0);
  const [dhkDays, setDhkDays] = useState(0);

  const CalDiffDays = (sdt,edt) =>{
    return Math.ceil((edt.getTime()-sdt.getTime())/(1000*3600*24));
  }

  useEffect(() => {

    //경기수, 유저, 시청시간 등이 포함된 정보 가져오기
    var url = config.API_SERVER_URI + "/select/admin/lastdatetime"
    axios.post(url, {
    })
    .then(function (response) {
      console.log(response.data);

      for(var i in response.data){
        var this_data = response.data[i]
        var this_lastdate = this_data.admin_last_datetime
        if(this_data.admin_name == "유재석"){
          var dateDiff =CalDiffDays(new Date(this_lastdate), new Date())
          setYooDays(dateDiff)
        }
        else if(this_data.admin_name == "최성일"){
          var dateDiff =CalDiffDays(new Date(this_lastdate),new Date())
          setSungilDays(dateDiff)
        }
        else if(this_data.admin_name == "권태현"){
          var dateDiff =CalDiffDays(new Date(this_lastdate),new Date())
          setTeniDays(dateDiff)
        }
        else if(this_data.admin_name == "김단하"){
          var dateDiff =CalDiffDays(new Date(this_lastdate),new Date())
          setDhkDays(dateDiff)
        }
      }


    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
    });

  }, [])

  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = props.rtlActive ? prop.rtlName : prop.name;
      }
      return null;
    });
    return name;
  }

  function makeBrandTest() {
    return (
      <div className="AnimationBackground">
        <div />
      </div>
    )
  }

  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <div className="AnimationBackground">
            <div className="AniOutBox">
              <div className="sungil" />
              <div>{sungilDays}일</div>
            </div>
            <div className="AniOutBox">
              <div className="teni" />
              <div>{teniDays}일</div>
            </div>
          </div>
          {/*makeBrandTest()*/}
          {/* Here we create navbar brand, based on route name
          <Button color="transparent" href="#" className={classes.title}>
            {makeBrandTest()}
          </Button>
          */}
        </div>

        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
