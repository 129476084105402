/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Room from "@material-ui/icons/Room";
import Language from "@material-ui/icons/Language";
import CreditCard from "@material-ui/icons/CreditCard";
import ListAltIcon from '@material-ui/icons/ListAlt';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import User from "views/User/User.js";
import PlayList from "views/PlayList/PlayList.js";
import ApplyList from "views/ApplyList/ApplyList.js";
import Accounting from "views/Accounting/Accounting.js";
import Map from "views/Map/Map.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "대시보드",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/user",
    name: "유저",
    icon: Person,
    component: User,
    layout: "/admin"
  },
  {
    path: "/playlist",
    name: "경기 리스트",
    icon: ListAltIcon,
    component: PlayList,
    layout: "/admin"
  },
  {
    path: "/applylist",
    name: "홈런, 개인영상",
    icon: LibraryBooks,
    component: ApplyList,
    layout: "/admin"
  },
  {
    path: "/map",
    name: "지도",
    icon: Room,
    component: Map,
    layout: "/admin"
  },
  {
    path: "/accounting",
    name: "입출금내역",
    icon: CreditCard,
    component: Accounting,
    layout: "/admin"
  }

];

export default dashboardRoutes;
