import React, { useState, useEffect } from 'react';
import bcrypt from "bcryptjs";
import axios from "axios";
import { Switch, Route, Redirect } from "react-router-dom";
import LibraryBooks from "@material-ui/icons/LibraryBooks";

import TextField from '@material-ui/core/TextField';

import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import CardIcon from "../components/Card/CardIcon.js";
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import Button from "../components/CustomButtons/Button.js";

import * as config from '../config.js'

export default function Admin() {
  const [loginID, setLoginID] = useState('');
  const [loginPW, setLoginPW] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleLoginIDChange = (event) => {
    setLoginID(event.target.value);
  }

  const handleLoginPWChange = (event) => {
    setLoginPW(event.target.value);
  }

  const handleEnterPress = (e) =>{
    if(e.key == 'Enter'){
      insertAdminSend();
    }
  }

  const insertAdminSend = () =>{

    setIsProcessing(true);

    const url = config.API_SERVER_URI +"/getSalt"
    axios.post(url, {
        admin_login_id : loginID
    })
    .then(function (response) {
      console.log(response.data);
      if(response.data.response == false){
        alert("유효하지 않은 ID 입니다.")
        return
      }
      //서버에서 salt값 받아서 로그인 요청
      const salt = response.data.salt
      var hash = bcrypt.hashSync(loginPW, salt);
      const url = config.API_SERVER_URI +"/login"
      axios.post(url, {
        admin_login_id : loginID,
        admin_login_password : hash
      })
      .then(function (response) {
        console.log(response.data);
        if(response.data.response == true){
          localStorage.setItem('access_token',response.data.accesss_token);
          localStorage.setItem('refresh_token',response.data.refresh_token);
          window.location.href="admin/dashborad"
        }
        else{
          alert('ID 혹은 비밀번호가 잘못되었습니다.')
          return
        }
      }).catch(function (error) {
        console.log(error);
      }).then(function() {
      });

    }).catch(function (error) {
      console.log(error);
    }).then(function() {
    });
  }

  const tokenRefresh = () => {
    const refresh_token = localStorage.getItem('refresh_token');
    const url = config.API_SERVER_URI + "/reissueToken";

    axios.post(url, {},{
      headers : {
        'Authorization' : 'Bearer ' + refresh_token
      }
    })
    .then(function (response) {
      if(response.data.response == true){
        window.location.href="/admin/dashboard"
      }
      else{
        alert("재로그인이 필요합니다.");
      }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
    });
  }

  useEffect(() => {

    const access_token = localStorage.getItem('access_token');
    if(access_token == null || access_token == undefined){
      //alert("로그인이 필요합니다.")
    }
    else{
      tokenRefresh();
    }

  }, [])

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="rose">
            <LibraryBooks />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <TextField
                margin="dense"
                label="아이디"
                type="text"
                value={loginID}
                onKeyPress={handleEnterPress}
                onChange={handleLoginIDChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                margin="dense"
                label="비밀번호"
                type="password"
                value={loginPW}
                onKeyPress={handleEnterPress}
                onChange={handleLoginPWChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12}>
              <Button fullWidth onClick={insertAdminSend} color="success" autoFocus>
                로그인
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

    </div>
  );
}
