import React, { useState, useEffect } from 'react';
import axios from "axios";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as config from '../../config.js'

export default function PlayEndDialog(props) {

  const [playDate, setPlayDate] = useState('');

  const handleCommit = () => {
    console.log(props.playEndIndex);
    const access_token = localStorage.getItem('access_token');

    const url = config.API_SERVER_URI + "/autho/update/play/one"
    const thisUserList = []
    axios.post(url, {
      play_index : props.playEndIndex
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    }).then(function() {
      props.handleEndDialogClose();
      props.LoadPlays();
    });
  };

  return (
    <Dialog
      open={props.playEndDialogOpen}
      onClose={props.handleEndDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">경기 완료 처리</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            props.infoIndexDict[props.playEndIndex] != undefined &&
            props.infoIndexDict[props.playEndIndex]['play_date']+"경기를 완료 처리하시겠습니까?"
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleEndDialogClose} color="primary">
          취소
        </Button>
        <Button onClick={handleCommit} color="primary" autoFocus>
          완료
        </Button>
      </DialogActions>
      </Dialog>
  );
}
