import React, { useState, useEffect } from 'react';
import axios from "axios";
import dateFormat from "dateformat";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import CreditCard from "@material-ui/icons/CreditCard";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import AccountingItemDialog from "components/Dialog/AccountingItemDialog.js";
import AccountingInsertDialog from "components/Dialog/AccountingInsertDialog.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import * as config from '../../config.js'

const styles = {
  formRoot: {
    float : "right",
    marginRight : "15px",
    padding : "2px"
  },
  addButton: {
    float : "right",
    marginRight : "15px"
  }
};

const useStyles = makeStyles(styles);
const week = ['일', '월', '화', '수', '목', '금', '토'];

const money_in_category = ['매출','지원금','입금','구장매출','이자수익']
const money_out_category = ['인건비','사무실비','설치비용','보험료','마케팅비','비품구입','지급수수료','세무비용','기자재구입','세금','여비교통비',
                            '수선비','복지비','서버비용']

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function Accounting() {
  const classes = useStyles();
  const [accountingDialogOpen,setAccountingItemDialogOpen] = useState(false);
  const [accountingInsertDialogOpen,setAccountingInsertDialogOpen] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [insertAccountingList, setInsertAccountingList] = useState([]);
  const [moneyInByType, setMoneyInByType] = useState([]);
  const [moneyOutByType, setMoneyOutByType] = useState([]);
  const [graphOpen, setGraphOpen] = useState(false);

  const makeNumberDot = (target_number) =>{
    return target_number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  }

  const timestampToDateString = (timestamp) =>{
    const datetime = new Date(timestamp)
    const dateString = dateFormat(datetime, "yy년mm월dd일("+ week[datetime.getDay()]+") HH:MM");
    return dateString;
  }

  const handleGraphOpenChange = (event) => {
    setGraphOpen(event.target.checked);
  };

  const handleItemDialogOpen = () => {
      setAccountingItemDialogOpen(true);
  };

  const handleItemDialogClose = () => {
    setAccountingItemDialogOpen(false);
  };

  const handleInsertDialogOpen = () => {
    setAccountingInsertDialogOpen(true);
  };
  const handleInsertDialogClose = () => {
    setAccountingInsertDialogOpen(false);
  };

  const tokenRefresh = () => {
    const refresh_token = localStorage.getItem('refresh_token');
    const url = config.API_SERVER_URI + "/reissueToken";

    axios.post(url, {},{
      headers : {
        'Authorization' : 'Bearer ' + refresh_token
      }
    })
    .then(function (response) {
      if(response.data.response == true){
        //토큰 업데이트
        localStorage.setItem('access_token',response.data.accesss_token);
        if(response.data.refresh_token){
          localStorage.setItem('refresh_token',response.data.refresh_token);
        }
      }
      else{
        alert("재로그인이 필요합니다.");
        window.location.href="/login"
      }

    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
    });
  }
  const LoadAccountings = () =>{
    const access_token = localStorage.getItem('access_token');
    var url = config.API_SERVER_URI + "/autho/select/accounting"
    const thisAccountList = []
    var money_in_dic = {}
    var money_out_dic = {}
    axios.post(url, {
    },{
      headers : {
        'Authorization' : 'Bearer ' + access_token
      }
    })
    .then(function (response) {
      for(const index in response.data){
        const this_data = response.data[index]

        //수입의 경우
        if(money_in_category.includes(this_data['accounting_type'])){
          const this_money = this_data['accounting_money_in'] - this_data['accounting_money_out']
          if(this_data['accounting_type'] in money_in_dic){
            money_in_dic[this_data['accounting_type']] += this_money
          }
          else{
            money_in_dic[this_data['accounting_type']] = this_money
          }
        }
        //비용의 경우
        else{
          const this_money = this_data['accounting_money_out'] - this_data['accounting_money_in']
          if(this_data['accounting_type'] in money_out_dic){
            money_out_dic[this_data['accounting_type']] += this_money
          }
          else{
            money_out_dic[this_data['accounting_type']] = this_money
          }
        }

        thisAccountList.push([parseInt(index)+1,this_data['accounting_type'],
          timestampToDateString(this_data['accounting_date']),this_data['accounting_info'],
          makeNumberDot(this_data['accounting_money_out']),makeNumberDot(this_data['accounting_money_in']),
          makeNumberDot(this_data['accounting_money_total'])
        ])
      }



    }).catch(function (error) {
        // 오류발생시 실행
        console.log(error);
    }).then(function() {
        // 항상 실행
        setAccountList(thisAccountList);
        const thisMoneyInList = Object.entries(money_in_dic)
        const thisMoneyOutList = Object.entries(money_out_dic)
        setMoneyInByType(thisMoneyInList);
        setMoneyOutByType(thisMoneyOutList);

    });
  }

  const handleFileChange = async (e) =>{
    console.log(e.target.files[0]);

    const reader = new FileReader()

    reader.onload = async (e) => {
      const text = (e.target.result)
      const fileAccountingList = []
      const text_rows = text.split('\n');

      for(var i in text_rows){
        if(i == 0) continue
        const this_row = text_rows[i]
        const row_split = this_row.split('|')
        //console.log(row_split);

        var this_date = row_split[1].replaceAll('.','-')
        this_date += ":00"
        const this_info = row_split[2]
        const this_name = row_split[3]
        const this_money_out = row_split[4].replaceAll(',','')
        const this_money_in = row_split[5].replaceAll(',','')
        const this_money_total = row_split[6].replaceAll(',','')
        fileAccountingList.push([this_date, this_name, parseInt(this_money_out), parseInt(this_money_in), parseInt(this_money_total)])

      }
      setInsertAccountingList(fileAccountingList);
      handleInsertDialogOpen();
    };

    reader.readAsText(e.target.files[0],"EUC-KR");

    e.target.value = null;
  }
  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    if(access_token == null || access_token == undefined){
      alert("로그인이 필요합니다.")
      window.location.href="/login"
    }
    else{
      tokenRefresh();
    }

    LoadAccountings();

  }, [])

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="rose">
            <CreditCard />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <div>
            <Button
              variant="contained"
              component="label"
            >
              Upload File
              <input
                type="file"
                onChange={handleFileChange}
                hidden
              />
            </Button>
            <Button type="button" color="info" className={classes.addButton} onClick={handleItemDialogOpen}>추가</Button>
            <FormControlLabel
              className = {classes.formRoot}
              control={<IOSSwitch checked={graphOpen} onChange={handleGraphOpenChange} name="checkedB" />}
              //label="통계"
            />
          </div>

          {graphOpen ?
            <Table
              tableHeaderColor="primary"
              tableHead={["순번","구분", "거래일", "내용", "비용", "수입", "거래 후 잔액"]}
              tableData={accountList}
            />
            :
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                      chart: {
                          type: 'pie',
                          options3d: {
                              enabled: true,
                              alpha: 45
                          }
                      },
                      title: {
                          text: ''
                      },
                      plotOptions: {
                          pie: {
                              allowPointSelect: true,
                              cursor: 'pointer',
                              dataLabels: {
                                  enabled: true,
                                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                                  connectorColor: 'silver'
                              }
                          }
                      },
                      series: [{
                          name: '수입',
                          data: moneyInByType
                      }]
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                      chart: {
                          type: 'pie',
                          options3d: {
                              enabled: true,
                              alpha: 45
                          }
                      },
                      title: {
                          text: ''
                      },
                      plotOptions: {
                          pie: {
                              allowPointSelect: true,
                              cursor: 'pointer',
                              dataLabels: {
                                  enabled: true,
                                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                                  connectorColor: 'silver'
                              }
                          }
                      },
                      series: [{
                          name: '비용',
                          data: moneyOutByType
                      }]
                  }}
                />
              </GridItem>
            </GridContainer>
          }
        </CardBody>
      </Card>
      <AccountingItemDialog
        accountingDialogOpen={accountingDialogOpen}
        handleItemDialogClose={handleItemDialogClose}
        LoadAccountings={LoadAccountings}
      />

      <AccountingInsertDialog
        accountingInsertDialogOpen={accountingInsertDialogOpen}
        handleInsertDialogClose={handleInsertDialogClose}
        insertAccountingList={insertAccountingList}
        LoadAccountings={LoadAccountings}
      />
    </div>
  );
}
